<template>
  <div class="personal">
    <div class="personal-title">
      我的信息
    </div>
    <el-row>
      <el-col :span="5">
        <div class="image-box">
          <img :src="personForm.headportrait"/>
          <el-upload
              :action="uploadAction"
              :data="uploadParam"
              :headers="headers"
              :on-success="onUploadSuccess"
              :show-file-list="false">
            <a>[修改头像]</a>
          </el-upload>

        </div>

      </el-col>
      <el-col :span="19">
        <div>
          <!-- <el-form ref="personForm" :label-position="labelPosition" :model="personForm" :rules="rules"
                    label-width="80px">
               <el-form-item label="账号">
                   <el-input v-model="personForm.username" disabled="disabled"></el-input>
               </el-form-item>
               <el-form-item label="昵称">
                   <el-input v-model="personForm.staffName"></el-input>
               </el-form-item>
               <el-form-item label="生日">
                   <el-date-picker
                           v-model="personForm.birthday"
                           type="date"
                           placeholder="选择日期">
                   </el-date-picker>
               </el-form-item>
   <el-form-item label="证件类型">
     <el-select v-model="personForm.certificatetype" placeholder="请选择证件类型">
       <el-option label="身份证" :value="1"></el-option>
       <el-option label="户口本" :value="2"></el-option>
     </el-select>
   </el-form-item>
   <el-form-item label="证件号码" prop="certificatecode">
     <el-input v-model="personForm.certificatecode"></el-input>
   </el-form-item>
               <el-form-item label="手机号码" prop="telephone">
                   <el-input v-model="personForm.telephone" placeholder="请输入"></el-input>
               </el-form-item>
               <el-form-item>
                   <el-button type="primary" @click="submitForm('personForm')">保存</el-button>
               </el-form-item>
           </el-form> -->
          <div class="infor-box">
            <div class="list-row">
              <div class="lable">用户名</div>
              <div class="infor">{{ personForm.username }}</div>
            </div>
            <div class="list-row">
              <div class="lable">昵称</div>
              <div class="infor">{{ personForm.staffName }}</div>
<!--              <div class="sfsm">[已实名]</div>-->
            </div>
<!--            <div class="list-row">-->
<!--              <div class="lable">手机号码</div>-->
<!--              <div class="infor">{{ personForm.telephone }}</div>-->
<!--              <div class="change-btn mg" @click="dialogPhone = true">[修改]</div>-->
<!--            </div>-->
            <div class="list-row">
              <div class="lable">登录密码</div>
              <div class="infor">已设置</div>
              <div class="change-btn" @click="dialogPwd = true">[修改]</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
        :visible.sync="dialogPhone"
        title="修改手机号"
        width="600px">
      <el-form ref="phonesetForm" :label-position="labelPosition" :model="phonesetForm" :rules="rules" label-width="100px">
        <el-form-item label="新手机号" prop="newPhone">
          <el-input v-model="phonesetForm.newPhone" placeholder="请输入新手机号"></el-input>
        </el-form-item>
        <el-form-item label="确认新手机号" prop="confirmPhone">
          <el-input v-model="phonesetForm.confirmPhone" placeholder="请再次输入新手机号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogPhone = false">取 消</el-button>
		    <el-button type="primary" @click="updateTelete()">确 定</el-button>
		  </span>
    </el-dialog>
    <el-dialog
        class="modify-password"
        :visible.sync="dialogPwd"
        title="修改密码"
        width="600px">
      <el-form :model="retrievePasswordForm" :rules="rules" ref="retrievePasswordForm" label-width="130px">
        <el-form-item label="新密码" prop="passWord">
          <el-input v-model="retrievePasswordForm.passWord" placeholder="请输入新密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPwd">
          <el-input v-model="retrievePasswordForm.confirmPwd" placeholder="请再次输入新密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="mobile" label-width="130px">
          <el-input v-model="retrievePasswordForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="verCode" class="mar"  label-width="130px">
          <el-input v-model="retrievePasswordForm.verCode" placeholder="请输入短信验证码">
            <el-button :class="{'sent': retrievePasswordForm.isSend}" slot="append" @click="getCode">
              <span v-if="!retrievePasswordForm.isSend">获取验证码</span>
              <span v-else>{{retrievePasswordForm.s}}S后重发</span>
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogPwd = false">取 消</el-button>
		    <el-button type="primary" @click="ConfirmRevise()">确 定</el-button>
		  </span>
    </el-dialog>
  </div>
</template>
<script>
import storage from '@/utils/storage';
import {updatePassword, updateUserInfo, userInfo} from "../../../api/systemService";
import urlConfig from "../../../utils/urlConfig";
import {GenerateUuid} from "../../../utils/uuid-utils";
import loginService from "../../../api/loginService";
import {isNull} from "../../../utils/utils-function";

export default {
  components: {},
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      }else{
        var patten = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
        if (patten.test(value)) {
          callback();
        } else {
          callback(new Error('请输入有效手机号码'))
        }
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        var pPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
        if (pPattern.test(value)) {
          callback();
        } else {
          callback(new Error('最少6位(包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符)'))
        }

      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.retrievePasswordForm.passWord) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      uploadAction: urlConfig.upLoadUrl + '/graph/saveGraphImage?twlx=tx',
      uploadParam: {
        saveLocal: 'false'
      },
      labelPosition: 'left',
      personForm: {
        headportrait: '',
        username: '',
        staffName: '',
      },
      userid: '',
      rules: {
        mobile: [
          {validator: validatePhone, required: true, trigger: 'blur'}
        ],
        passWord: [
          {validator: validatePass, required: true, trigger: 'blur'}
        ],
        confirmPwd: [
          {validator: validatePass2, required: true, trigger: 'blur'}
        ],
      },
      headers: {
        'X-Gisq-Token': ''
      },
      dialogPhone: false,
      phonesetForm: {
        newPhone: '',
        confirmPhone: ''
      },
      dialogPwd: false,
      retrievePasswordForm: {
        passWord: '',
        confirmPwd: '',
        mobile: '',
        verCode: '',
        s: 60,
        isSend: false,
      },
      uuid:'',
    }
  },
  methods: {
    onUploadSuccess(response, file, fileList) {
      this.personForm.headportrait = response.data.finalUrl;
      const param = {
        headportrait:response.data.finalUrl,
        username: this.personForm.username,
        staffName: this.personForm.staffName,
      }
      updateUserInfo(this.userid, param).then(res => {
        const user = storage.get('user');
        user.user_nickname = encodeURIComponent(res.data.staffName);
        console.log(res)
        user.headportrait = res.data.headportrait;
        storage.remove('user');
        storage.set('user', user);
        this.$message({
          message: '修改头像成功！',
          type: 'success'
        });
      });
    },
    updateTelete() {
      if (this.phonesetForm.newPhone !== '' && this.phonesetForm.newPhone !== null && this.phonesetForm.newPhone !== undefined && this.phonesetForm.newPhone === this.phonesetForm.confirmPhone) {
        const myreg = /^1[0-9]{10}$/;

        if (!myreg.test(this.phonesetForm.newPhone)) {
          this.$message({
            message: '手机号格式输入不正确！',
            type: 'error'
          });
          return
        }
        this.personForm.telephone = this.phonesetForm.newPhone
        updateUserInfo(this.userid, this.personForm).then(res => {
          const user = storage.get('user');
          user.user_nickname = encodeURIComponent(res.data.staffName);
          user.headportrait = res.data.headportrait;
          storage.remove('user');
          storage.set('user', user);
          this.dialogPhone = false
          this.$message({
            message: '保存成功！',
            type: 'success'
          });

        });
      }else{
        this.$message({
          message: '手机号输入不正确！',
          type: 'error'
        });
      }

    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateUserInfo(this.userid, this.personForm).then(res => {
            const user = storage.get('user');
            user.user_nickname = encodeURIComponent(res.data.staffName);
            user.headportrait = res.data.headportrait;
            storage.remove('user');
            storage.set('user', user);
            this.$message({
              message: '保存成功！',
              type: 'success'
            });
          });
        }
      });
    },
    ConfirmRevise(){
      this.$refs.retrievePasswordForm.validate((valid) => {
        if(valid){
          if(this.retrievePasswordForm.verCode===''){
            this.$message.warning("请输入验证码！");
            return
          }
          this.uuid = sessionStorage.getItem('registerKey');
          //获取验证码接口
          let param = {
            username: this.retrievePasswordForm.mobile,
            passWord: this.retrievePasswordForm.passWord
          };
          const loading = this.$loading({
            lock: true,
            text: '重置中...',
            background: 'rgba(255, 255, 255, 0.7)'
          });
          loginService.updatePassword(this.uuid,this.retrievePasswordForm.verCode,param).then(res => {
            loading.close();
            console.log(res)
            if(res.data.includes('成功') || res.code === '0'){
              this.$message({
                message: '修改成功！请重新登录！',
                type: 'success',
                duration: 5000
              });
              this.$router.push({
                name: 'login',
              })
            }
          }).catch((e) => {
            console.log(e);
            loading.close()
          })
        }
      })
    },
    /**
     * 加载验证码
     */
    getCode() {
      this.uuid = GenerateUuid();
      sessionStorage.setItem("registerKey", this.uuid)
      //获取验证码接口
      const loading = this.$loading({
        lock: true,
        text: '获取中...',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      loginService.getIdentifyingCode(this.uuid,this.retrievePasswordForm.mobile).then(res => {
        loading.close();
        console.log(res)
        this.setTime()
      }).catch(e => {
        loading.close();
        this.$message.error(e);
      })
    },
    setTime() {
      if (this.retrievePasswordForm.s === 0) {
        this.retrievePasswordForm.isSend = false
        return;
      } else {
        this.retrievePasswordForm.isSend = true
        this.retrievePasswordForm.s--;
      }
      let _this = this;
      setTimeout(function () {
        _this.setTime()
      }, 1000)//每1000毫秒执行一次
    },
  },
  mounted() {
    const user = storage.get('user');
    this.userid = user.user_id;
    console.log(user);
    userInfo(this.userid).then(res => {
      if (res.code != '401') {
        this.personForm = res.data;
        if (res.data.headportrait == null) {
          this.personForm.headportrait = '/image/dheader.jpg'
        }
      } else {
        storage.remove('user');
        this.$router.push({
          path: '/login'
        })
      }

    });
    this.headers['X-Gisq-Token'] = 'Bearer ' + storage.get('user').access_token;
  }
}
</script>
<style lang="scss">
.personal {
  padding: 20px;
  box-sizing: border-box;
  // border:1px solid blueviolet;
  height: 440px;

  .personal-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-bottom: 40px;
  }

  .el-row {
    .el-col-5 {
      width: 340px;

      .image-box {
        width: 140px;
        height: 140px;
        background: #F5F7FA;
        border: 1px solid #CFDEE6;
        border-radius: 10px;
        margin: 0px 100px 0px 80px;

        img {
          width: 100%;
          height: 140px;
          border-radius: 10px;
        }

        a {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0080FF;
          line-height: 14px;
          padding: 15px 0px;
          text-align: center;
          display: block;
          width: 140px;
        }
      }
    }

    .el-col-19 {
      width: calc(100% - 340px);

      .el-col-8:last-child {
        padding-right: 0px !important;
      }

      .el-select {
        width: 100%;
      }

      .el-button--primary {
        width: 100%;
      }

      .infor-box {
        width: 100%;
        height: 180px;
        background: rgba(3, 155, 229, 0);
        box-shadow: -1px 0px 0px 0px #A3BECC;
        padding-left: 100px;
        box-sizing: border-box;

        .list-row {
          display: flex;
          flex-direction: row;
          margin-top: 38px;

          .lable {
            width: 58px;
            text-align: right;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
          }

          .infor {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 16px;
            margin-left: 30px;
          }

          .sfsm {
            margin-left: 68px;
            font-size: 14px;
            color: #00B395;
            line-height: 16px;
          }

          .change-btn {
            margin-left: 68px;
            font-size: 14px;
            color: #007FFF;
            line-height: 16px;
            cursor: pointer;
          }

          .mg {
            margin-left: 20px;
          }
        }

        .list-row:nth-child(1) {
          margin-top: 0px;
        }
      }
    }
  }

  .el-dialog {
    .el-dialog__header {
      padding: 15px 20px 15px;
      background: #EBF2F5;
    }

    .el-dialog__footer {
      text-align: center;
      padding: 0px 20px 20px;

      .el-button {
        padding: 6px 30px;
      }
    }
  }
}
.modify-password{
  .el-form {
    .el-form-item {
      .el-form-item__label {
        font-size: 16px;
        color: #333;
      }
      .el-form-item__content {
        font-size: 16px;
        .el-checkbox__label {
          font-size: 16px;
        }
        .is-account {
          a {
            color: #1AA4E7;
          }
        }
        .el-input-group__append {
          .el-button {
            width: 120px;
            font-size: 16px;
            color: #fff;
            text-align: center;
            padding: 9px 20px 10px;;
            background-color: #039be5;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          .sent {
            color: #999;
            cursor: no-drop;
            background-color: #E5E5E5;
          }
        }
      }
    }

    .signup-tips {
      .agreement {
        color: #1AA4E7;
        cursor: pointer;
      }
    }

    .signup-btn {
      .el-button {
        color: #fff;
        width: 100%;
        background: rgba(3, 155, 229, 1);
        border-radius: 4px;
        font-size: 16px;
      }
    }
    .mar {
      margin-bottom: 10px;
    }
  }
}
</style>
